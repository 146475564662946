.abv {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 15px;
    font-style: italic;
    padding-right: 5px;
}

.description {
    margin-right: 10%;
    margin-left: 5px;
    margin-bottom: 2%;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    text-align: justify;
    text-justify: auto;
}

.drink-image {
    text-align: right;
    padding-right: 2%;
}

.drink-image>img {
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
}

.emu {
    margin-top: 5px;
    margin-left: 5px;
    font-size: 15px;
    color: darkgray;
}

.info-column {
    float: left;
    width: 50%;
}

.info-text {
    padding-right: 2%;
    text-align: left;
}

.info-row:after {
    content: "";
    display: table;
    clear: both;
}

.info-title {
    font-family: Roboto;
    font-weight: 100;
    font-size: 60px;
    margin-bottom: 0;
    margin-top: 0;
}

.ingredients {
    margin-right: 10%;
    margin-left: 5px;
    margin-bottom: 3%;
    margin-top: 0;
    padding-left: 0;
    list-style: none;
    font-size: 22px;
    font-weight: 300;
}

.instructions {
    margin-right: 10%;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 3%;
    font-size: 22px;
    font-weight: 300;
    text-align: justify;
    text-justify: auto;
}

.volume {
    margin-top: 5px;
    font-size: 15px;
    color: darkgray;
}

.footnote {
    margin-top: 0px;
    margin-left: 4px;
    font-style: italic;
    color: darkgray;
    font-size: 15px;
    font-weight: 300;
}

.loading-icon {
    width: 250px;
    padding: 100px;
    animation-name: shake;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@media screen and (max-width: 600px) {
    .description {
        margin-right: 10px;
        font-size: 18px;
    }

    .footnote {
        margin-right: 10px;
        margin-top: 15px;
        font-size: 16px;
    }

    .ingredients {
        margin-bottom: 15px;
        font-size: 22px;
    }

    .instructions {
        margin-right: 10px;
        margin-bottom: 15px;
        font-size: 22px;
    }

    .info-column {
        width: 100%;
    }

    .info-column>.image {
        margin-top: 50px;
    }

    .loading-icon {
        width: 100px;
        margin: 50% 0px 50% 0px;
    }

    .drink-multiplier-container button {
        background-color: #000000;
    }
    
    .multiplier-quantity {
        background-color: #000000;
    }
}