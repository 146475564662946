.back {
    font-size: 30px;
    padding-top: 12px;
    margin-right: 10px;
    margin-left: 1%;
}

nav {
    margin-bottom: 10px;
}

nav > div {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.nav-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.nav-logo {
    color: white;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 300;
    text-align: left;
}

.share-button {
    display: none;
}

@media screen and (max-width: 600px) {
    nav {
        width: 100%;
        background-color: #1D1E1F;
        top: 0px;
        left: 0px;
        margin-bottom: 0px;
    }
    
    nav {
        background-color: #000000;
    }

    .share-button {
        display: grid;
        float: right;
        cursor: pointer;
        font-size: 40px;
        padding-top: 3px;
        margin-right: 11px;
    }
}