.create-drink > p {
    display: flex;
    justify-content: center;
    margin-bottom: -15px;
    margin-top: -5px;
}

.create-drink-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.create-drink-image > img {
    max-width: 100%;
    height: auto;
    width: auto;
    object-fit: cover;
}

.create-drink-image > input {
    margin-left: 166px;
}

.create-drink-row {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.create-drink-row > p {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;
}

.create-drink-title {
    display: flex;
    margin-bottom: 5px;
    justify-content: center;
    font-family: Roboto;
    font-weight: 300;
}