.clickable {
    cursor: pointer;
}

.filter-category-container {
    padding-left: 2px;
    padding-right: 2px;
}

.filter-category-title {
    font-size: 22px;
    font-weight: 300;
    margin-top: 0px;
}

.filter-chevron {
    display: flex;
    justify-content: center;
    padding: 8px;
}

.filter-eraser {
    float: right;
    margin: 5px 0 0 10px;
}

.filter-panel-container {
    display: flex;
    justify-content: center;
}

.filter-toggle {
    float: right;
    margin: 5px 0 0 10px;
}

@media screen and (max-width: 1070px) {
    .filter-panel-container {
        flex-direction: column;
    }

    .filter-category-container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .filter-category-tag-container {
        display: flex;
        flex-flow: row wrap;
    }

    .filter-category-title {
        margin-top: 10px;
        margin-bottom: 2px;
    }

    @keyframes slideDown{
        from {
            max-height: 0;
        }
        to {
            max-height: 1035px;
        }
    }
    
    @keyframes slideUp{
        from {
            max-height: 1035px;
        }
        to {
            max-height: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .filter-category-container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: left;
    }

    .filter-match-title {
        padding-left: 5px;
        font-size: 30px;
    }

    .filter-match-none {
        padding-left: 5px;
    }
}

@media screen and (max-width: 600px) {
    .unselected-tag-filter {
        background-color: #000000;
    }
}