.all-ingredients-title {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 30px;
}

.ingredient-entry {
    padding: 0px;
    margin: 2px 0px 0px 0px;
    font-size: 20px;
    font-weight: 300;
}

.ingredient-use-count {
    padding-top: 6px;
    margin: 0px 0px 0px 5px;
    font-size: 14px;
    font-weight: 300;
}

.sorted-filter-icon {
    cursor: pointer;
    font-size: 32px;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
}

@media screen and (max-width: 600px) {
    .all-ingredients-title {
        font-weight: 300;
    }

    .ingredient-entry {
        font-size: 25px;
        margin-top: 5px;
    }

    .ingredient-use-count {
        font-size: 20px;
        margin-top: 2px;
    }
}